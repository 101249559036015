import React from "react";
import "./sitemap.css";
import CompanyLogo from "../../src/images/logo.webp";
import ScrollArrow from "./scroll-to-top-button";
import Accessibility from "./AccessibilityWidget/components/AccessibilityWidget";

function sitemap() {
  return (
    <div className="sitemap-page-container">
      <center>
        <div style={{ width: "100%", position: "relative" }}>
          <a href="/" className="link">
            <img
              src={CompanyLogo}
              alt="Company Logo"
              className="sitemap-company-logo"
            />
          </a>
          <ScrollArrow />
          <Accessibility />
        </div>
        <div className="sitemap-page-contents">
          <div className="sitemap-page-topic-section">
            <h1>Site Map</h1>
          </div>
          <div className="sitemap-page-content-section">
            <div className="sitemap-page-coloumn">
              <h2>Pages</h2>
              <div className="sitemap-page-coloumn-sub-headings">
                <a href="/">Home</a>
                <a href="/about-us/">About Us</a>
                <a href="/esg/">ESG</a>
                <a href="/investor-relations/">Investor Relations</a>
                <a href="/contacts/">Contacts</a>
              </div>
            </div>
            <div className="sitemap-page-coloumn">
              <h2>Legal</h2>
              <div className="sitemap-page-coloumn-sub-headings">
                <a href="/privacy-policy/">Privacy Policy</a>
                <a href="/cookies-policy/">Cookie Policy</a>
              </div>
            </div>
          </div>
        </div>
      </center>
    </div>
  );
}

export default sitemap;
